import React from 'react';

const SubFooter = () => (
  <div className="subfooter">
    <p>
      {"That's all. Thank you. "}
      <a href="#home">Back to Top</a>
    </p>
  </div>
);

export default SubFooter;
